import appleIcon from "..//assets/res/apple_white.svg";
import androidIcon from "..//assets/res/android_white.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GET_BASE_URL } from "../utils/Constants";

const VerticalListItem = ({ game, handleItemClick, isForSearch = false }) => {
  const { name, image_path, rate, isForAndroid, isForIos } = game;

  return (
    <div className="flex flex-col mt-4 rounded-xl border-solid border-custom-orange border-[1px] mx-2 p-2">
      <div className=" flex flex-row ">
        <div className="h-16 w-16 rounded-xl bg-red-400 flex-none overflow-hidden">
          <div onClick={() => handleItemClick(game)}>
            <LazyLoadImage
              className="h-16 w-16 object-cover object-center"
              src={GET_BASE_URL() + "images/" + image_path}
              alt={name}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center ml-2 ">
          <div className="text-custom-light-gray medium text-xl break-words flex-initial">
            {name}
          </div>
          <div className="flex flex-row text-gray-400  medium items-center ">
            <div className="flex flex-row  mt-1 ">
              {isForAndroid && (
                <img src={androidIcon} className="h-4 w-4 mr-2" />
              )}
              {isForIos && <img src={appleIcon} className="h-4 w-4 " />}
            </div>
          </div>
        </div>
        <div className="grow h-full flex flex-row justify-end items-center my-auto">
          {isForSearch && (
            <div
              className="rounded-full bg-black semiBold  text-lg text-custom-orange px-6 pt-0.5 w-20 h-8 tracking-widest"
              onClick={() => handleItemClick(game)}
            >
              Install
            </div>
          )}
          {!isForSearch && (
            <div
              className="rounded-full bg-gray-900 semiBold  text-lg text-custom-orange pl-2 pt-0.5 w-20 h-8 tracking-widest"
              onClick={() => handleItemClick(game)}
            >
              Install
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerticalListItem;
