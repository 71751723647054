import { LazyLoadImage } from "react-lazy-load-image-component";
import { GET_BASE_URL } from "../utils/Constants";

const HorizontalListItem = ({ game, handleItemClick }) => {
  const { name, image_path, description, isForAndroid, isForIos } = game;

  return (
    <div class="inline-block ml-2 rounded-lg  border-solid border-custom-orange border-[1px]">
      <div class="relative w-screen h-52 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
        <div onClick={() => handleItemClick(game)}>
          <LazyLoadImage
            className="h-52 w-full object-cover object-center"
            src={GET_BASE_URL() + "images/" + image_path}
            alt={name}
          />
        </div>
        <div class="absolute bottom-0 left-0 flex  flex flex-row z-10 w-full backdrop-blur-xl">
          <div className="flex  flex flex-col p-2 w-3/4">
            <p class="text-xl medium text-white tracking-wider">{name}</p>
            <p class="text-s medium text-white tracking-wider line-clamp-2">
              {description}
            </p>
          </div>
          <div
            className=" w-1/4 flex flex-col justify-center items-end  w-1/4 mr-2"
            onClick={() => handleItemClick(game)}
          >
            <div className="rounded-full text-white medium text-lg bg-custom-orange  pl-2 pt-0.5 w-20 h-8 tracking-widest">
              Install
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalListItem;
