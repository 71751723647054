import { React, useState, useEffect, useCallback } from "react";
import { CircularProgress } from "@mui/material";
import Lottie from "lottie-react";

import appleIcon from "..//assets/res/apple_logo.svg";
import chromeIcon from "..//assets/res/chrome_logo.svg";
import androidIcon from "..//assets/res/android_logo.svg";
import closeIcon from "..//assets/res/close.svg";
import classNames from "classnames";

import Loading from "..//assets/lottie/loading_lottie.json";
import { GET_BASE_URL } from "../utils/Constants";

const PlatformDialogPicker = ({ selectedGame, onBackEvent }) => {
  const { name, apk_path, ipa_path, mirror_1, mirror_2, showMirrorLinks } =
    selectedGame;

  const [showMirrorButtons, setShowMirrorButtons] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [newName, setnewName] = useState("Initializing");

  let index = 0;
  let intervalID;

  const names = [
    "Check Compatibility",
    "Connecting Database",
    "Verifying Database",
    "Downloading Data",
    "Downloading Data",
    "Downloading Data",
    "Downloading Data",
    "Downloading Data",
    "Validate Data with Client",
    "All operations executed successfully.",
  ];

  const shuffle = useCallback(() => {
    console.log("asdasdasd");
    setnewName(names[index]);
    index = index + 1;
    if (index == names.length) {
      setShowMirrorButtons(true);
      clearInterval(intervalID);
    }
  }, []);

  useEffect(() => {
    intervalID = setInterval(shuffle, 3000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  function downloadApk() {
    setShowLoading(true);
    setTimeout(() => {
      window.open(GET_BASE_URL() + "apk/" + apk_path, "_self");
      onBackEvent();
    }, 2000);
  }

  function downloadIPA() {
    setShowLoading(true);
    setTimeout(() => {
      const fileUrl = GET_BASE_URL() + "ipa/" + ipa_path;
      const a = document.createElement("a");
      a.href = fileUrl;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      onBackEvent();
    }, 2000);
  }

  return (
    <div
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity w-screen"
        aria-hidden="true"
      ></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto w-screen">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 w-screen">
          <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full w-screen sm:max-w-lg">
            <div class="bg-white  pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class=" flex row flex-shrink-0  sm:mx-0 sm:h-10 sm:w-10 justify-end pr-4 w-full">
                  <img
                    src={closeIcon}
                    className="h-9 w-9"
                    onClick={onBackEvent}
                  />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  {showMirrorLinks && (
                    <div className="flex flex-col px-4">
                      {showMirrorButtons && (
                        <div className="medium text-xl text-green-500">
                          🎉 Your device is ready 🎉
                        </div>
                      )}
                      {!showMirrorButtons && (
                        <Lottie animationData={Loading} loop={true} />
                      )}

                      {!showMirrorButtons && (
                        <div className="text-gray-900 animate-pulse medium">
                          STATUS : {newName}
                        </div>
                      )}

                      {showMirrorButtons && (
                        <button
                          type="button"
                          onClick={() => {
                            window.open(mirror_1, "_blank");
                          }}
                          class="justify-center rounded-3xl bg-blue-600 px-3 py-2 text-sm font-semibold text-white mt-4 shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        >
                          Inject {name}
                        </button>
                      )}
                      {showMirrorButtons && (
                        <button
                          onClick={() => {
                            window.open(mirror_2, "_blank");
                          }}
                          class="justify-center rounded-3xl bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm mt-4 hover:bg-red-500 sm:ml-3 sm:w-auto"
                          type="button"
                        >
                          Inject {name} Mirror
                        </button>
                      )}
                    </div>
                  )}
                  {!showMirrorLinks && (
                    <div className="flex flex-row">
                      <div
                        className={`duration-300 px-4 flex flex-col ${
                          showLoading ? "translate-x-full" : "translate-x-0"
                        }`}
                      >
                        <h3
                          class="font-semibold leading-6 text-black text-2xl medium pb-8"
                          id="modal-title"
                        >
                          Selected your OS
                        </h3>
                        <div class="mt-2">
                          <div
                            className={`flex flex-row justify-center duration-300  ${
                              showLoading ? "translate-x-full" : "translate-x-0"
                            }`}
                          >
                            <div className="h-16 w-16 mr-6 animate-bounce">
                              <img
                                src={appleIcon}
                                className="h-16 w-16"
                                onClick={downloadIPA}
                              />
                            </div>

                            <div className="h-16 w-16 ml-6 animate-bounce">
                              <img
                                src={androidIcon}
                                className="h-16 w-16"
                                onClick={downloadApk}
                              />
                            </div>
                          </div>

                          <div
                            className={`duration-300 px-4 ${
                              showLoading ? "translate-x-full" : "translate-x-0"
                            }`}
                          >
                            {/*  <p class="semiBold text-xs text-red-500 width-full mt-2">
                              *CAUTION: For a smoother download experience,
                              please use CHROME:
                            </p> */}
                            <div className="flex flex-row mx-auto  items-center justify-center mt-2">
                              <img
                                src={chromeIcon}
                                className="h-[20px] w-[20px]"
                              />
                              <p class="semiBold text-[12px] text-red-500 width-full ml-2">
                                Use Chrome for a smoother download experience.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`flex flex-row w-full mt-10 items-center justify-center fixed duration-300 ${
                          showLoading ? "translate-x-0" : "-translate-x-full"
                        }`}
                      >
                        <div className="flex flex-row w-full bg-fixed items-center justify-center">
                          <CircularProgress />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformDialogPicker;
